<template>
  <div class="update-documents">
    <div class="update-document-title">
      {{ $t('views.update_documents.title') }}
    </div>
    <div>
      <form-documents
        :new-business="false"
        :is-to-edit="true"
        @submited="updateDocuments"
      />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import FormDocuments from '@/components/forms/register/business/FormDocuments.vue';

export default {
  name: 'UpdateDocuments',
  components: {
    FormDocuments,
  },
  computed: {
    ...mapGetters([
      'getBusiness',
      'getDocuments',
    ]),
  },
  methods: {
    ...mapActions(['updateBusinessDocuments']),

    updateDocuments(params) {
      this.updateBusinessDocuments({
        formData: params.formData,
        business_id: this.getBusiness.id,
        id: this.getDocuments.id,
      });
      this.$router.push({ name: 'document' });
    },
  },
};
</script>
<style lang="scss" scoped>
  .update-documents {
    background-color: $dashboard_background;
    min-height: 100vh;
    padding: 0px 40px 0px 270px;
    display: flex;
    flex-direction: column;

    .update-document-title {
      text-align: center;
      font-size: 1.5em;
      margin: 70px 0 40px 0;
      font-weight: bold;
    }
  }
</style>
